@import "../../../styles/base/abstracts";

.hbs-simple-page-topper {
  background-color: $c-bg;
  position: relative;
  &::before {
    content: "";
    @include absolute(x 0 100%);
    height: $nav-bg-height;
    background-color: $c-bg;
  }

  @each $theme in $themes {
    &[data-theme="#{$theme}"] + .hbs-breadcrumbs[data-theme="#{$theme}"] {
      @include border(top);
    }
  }

  & .hbs-media-asset {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 9;
    display: flex;
    align-items: flex-end;

    @include mq($bp-tablet) {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 5;
      grid-column-end: 9;

      padding-top: $spacing-md;
    }

    @include mq($bp-desktop) {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 7;
      grid-column-end: 9;
      margin-top: -$spacing-lg;
    }

    @include mq($bp-desktop-lg) {
      grid-row-start: 1;
      grid-row-end: 3;
      margin-top: -$spacing-lg;
    }
  }
}

.hbs-simple-page-topper__content {
  @include grid-parent;
  @include site-max-width;
  display: grid;
  grid-template-columns: $col-2 $col-2 $col-2 $col-2 $col-2 $col-2 $col-2 $col-2;
}

.hbs-simple-page-topper__title {
  @include grid-child;
  transform: translateX(-0.06em);
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 9;
  padding-bottom: $spacing-md;

  @include mq($bp-tablet) {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  @include mq($bp-tablet-xl) {
    grid-column-end: 7;
  }
}

.hbs-simple-page-topper__title--long {
  @include h2;
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 9;
  padding-bottom: $spacing-md;

  @include mq($bp-tablet) {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  @include mq($bp-desktop) {
    grid-column-end: 7;
  }
}

.hbs-simple-page-topper__title-media {
  @include grid-child;
  @include mq($bp-desktop) {
    padding-bottom: $spacing-sm !important;
    width: $col-16;
  }
  @include mq($bp-tablet) {
    grid-column-end: 8;
  }
  @include mq($bp-desktop-lg) {
    display: flex;
    align-items: flex-end;
    grid-column-end: 7;
  }
}

.hbs-simple-page-topper__text {
  @include grid-child;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 9;

  @include mq($bp-desktop) {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 6;
    padding-bottom: 0;
  }

  &--with-image {
    padding-bottom: $spacing-xl;

    @include mq($bp-tablet) {
      grid-row: 2;
      grid-column-start: 1;
      grid-column-end: 5;
      padding-right: $spacing-md;
      padding-top: $spacing-md;
    }
    @include mq($bp-desktop) {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    @include mq($bp-desktop-xl) {
      height: fit-content;
    }
  }
}

.hbs-simple-page-topper__intro {
  @include big-paragraph;
}

.hbs-simple-page-topper__cta {
  padding-top: $spacing-xl;
}
